var settings = {

	carousel: {

		// Transition speed (in ms)
		// For timing purposes only. It *must* match the transition speed of ".carousel > article".
			speed: 350

	}

};

(function($) {

	skel.breakpoints({
		xlarge:	'(max-width: 1680px)',
		large:	'(max-width: 1280px)',
		medium:	'(max-width: 980px)',
		small:	'(max-width: 736px)',
		xsmall:	'(max-width: 480px)'
	});


	$(function() {

		var	$window = $(window),
			$body = $('body'),
			$header = $('#header'),
			$banner = $('#banner');

		// Disable animations/transitions until the page has loaded.
		/*$body.addClass('is-loading');
		
					$window.on('load', function() {
						window.setTimeout(function() {
							$body.removeClass('is-loading');
						}, 100);
					});
*/
		// IE: Various fixes.
			if (skel.vars.browser == 'ie')
				$body.addClass('is-ie');

		// Prioritize "important" elements on medium.
			skel.on('+medium -medium', function() {
				$.prioritize(
					'.important\\28 medium\\29',
					skel.breakpoint('medium').active
				);
			});

		// Menu.
			$('#menu')
				.append('<a href="#menu" class="close"></a>')
				.appendTo($body)
				.panel({
					delay: 500,
					hideOnClick: true,
					hideOnSwipe: true,
					resetScroll: true,
					resetForms: true,
					side: 'right'
				});

		// Header.
			if ($banner.length > 0 && $header.hasClass('alt')) {

					$window.on('resize', function() { $window.trigger('scroll'); });

					$banner.scrollex({
							bottom:         $header.outerHeight(),
							terminate:      function() { $header.removeClass('alt'); },
							enter:          function() { $header.addClass('alt'); },
							leave:          function() { $header.removeClass('alt'); }
					});

			}

		// Images.
			$('.image[data-position]').each(function() {

				var $this = $(this),
					$img = $this.children('img');

				// Polyfill object-fit.
					if (!skel.canUse('object-fit')) {

						// Apply img as background.
							$this
								.css('background-image', 'url("' + $img.attr('src') + '")')
								.css('background-position', $this.data('position'))
								.css('background-size', 'cover')
								.css('background-repeat', 'no-repeat');

						// Hide img.
							$img
								.css('opacity', '0');

						return;

					}

			});

		// Scrolly.
			$('.scrolly').scrolly({
				offset: function() {
					return $header.outerHeight() - 2;
				}
			});

			$('.scrolly-middle').scrolly({
				anchor: 'middle',
				offset: function() {
					return $header.outerHeight() - 2;
				}
			});

		// Spotlights.
			$('.spotlight').scrollex({
				top:		'30vh',
				bottom:		'30vh',
				delay:		25,
				initialize:	function() {
					$(this).addClass('is-inactive');
				},
				terminate:	function() {
					$(this).removeClass('is-inactive');
				},
				enter:		function() {
					$(this).removeClass('is-inactive');
				}
			});

	});

	$('.inner-content img').attr('data-action', 'zoom');

})(jQuery);

$(document).ready(function() {
    $(".answer").hide();
    $('.question').click(function() {
 
        $(this).next('.answer').slideToggle(200);
        $(this).toggleClass('close');
 
	});
	
	$('.page-slider, .carousel').slick({
		infinite: true,
		speed: 500,
		fade: true,
		cssEase: 'linear',
		autoplay: true,
		autoplaySpeed: 3000,
	});

});